<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <CasesHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p>近来，常有投资人问<strong>为什么要办安提瓜护照？什么人群会选择办安提瓜护照？安提瓜护照的优势、用途等。</strong></p>
        <p>通过最近众多成功获得安提瓜护照的案例，小编将和大家具体分析安提瓜护照项目。</p>
        <p><strong>为公司业务，全球通行便捷</strong></p>
        <p>B先生经营一家大型贸易公司，一个月大约二十天都在国外。<strong>由于公司业务合作的关系，B先生经常飞往英国、申根国、新加坡等国家，</strong>这么飞来飞去已经许久了。</p>
        <p>繁琐冗长的签证手续给B先生带来诸多不便。在朋友的推荐下，B先生来到跃迁，办理了<strong>安提瓜护照</strong>。获得护照即可自由出入英国等多个英联邦国家，全球免签落地签150个国家，包含申根国家。</p>
        <div class="pgc-img"><img loading="lazy" class="size-full wp-image-20708 aligncenter" src="@/assets/Cases/18/1.jpg" width="640" height="427"></div>
        <p>如今B先生用安提瓜护照通行一年多了，方便省心，还推荐了几位朋友来办理。</p>
        <p>C先生通过房地产起家，名下资产众多，是一名高净值客户。深谙投资之道的他一直在考虑全球资产配置、税务等问题。跃迁建议了较理想的配置方案：办理安提瓜护照。</p>
        <p><strong>安提瓜不设全球税、个人所得税、资本利得税、遗产税。</strong>作为加勒比海地区离岸金融中心，其优越的税务制度吸引着各国高净值人群在此投资。以跃迁的安提瓜半月湾项目为例，20万美元即可三代移民，为资产配置和税务问题排忧解难。</p>
        <div class="pgc-img"><img loading="lazy" class="size-full wp-image-20709 aligncenter" src="@/assets/Cases/18/2.jpg" width="640" height="427"></div>
        <p>护照到手后，C先生在多地开设了个人账户，完成了资产配置，化解了顾虑。</p>
        <p><strong>品质宜居之地，度假养老</strong></p>
        <p>D先生前两年和家人一同去安提瓜旅游度假过，全家人都玩的很开心。父母也很喜欢当地的碧海蓝天，轻松生活。D先生由于工作繁忙，一直没有时间陪伴老人。</p>
        <p>为了弥补，决定为全家人办理了安提瓜护照，作为礼物送给父母。<strong>让他们能随时去安提瓜度假，养老。</strong></p>
        <div class="pgc-img"><img loading="lazy" class="size-full wp-image-20710 aligncenter" src="@/assets/Cases/18/3.jpg" width="640" height="432"></div>
        <p>安提瓜一直是欧美富豪偏爱的小众度假地，<strong>意大利前总理贝卢斯</strong>就在安提瓜购买了私人别墅。<strong>美国主持人奥普拉</strong>在安提瓜甚至有一座价值超过3千万美金的度假屋。<strong>这里环境优美，坐拥美丽的海滩、高品质度假村、是品质宜居之地。</strong></p>
        <p><strong>安提瓜护照项目优势</strong></p>
        <p>1.CRS税务透明机制下，资产配置解决方案；</p>
        <p>2.优越的税制，没有全球税、个人所得税、资本利得税、遗产税。加勒比海地区离岸金融中心；</p>
        <p>3.获得护照即可自由出入英国等多个英联邦国家，全球免签落地签150个国家，包含申根国家，如持有安提瓜护照可申请美国签证，一般可获发为期10年有效的多次往返签证；</p>
        <p>4.先批准，后投资；</p>
        <p>5.申请快捷简单：申请文件简单，客户无需面试，申请后4-6个月即可获得护照；</p>
        <p>6.申请条件宽松：无背景要求，对申请人学历、英语能力、管理经验、资金金额及资产来源均无要求；</p>
        <p>7.20万美元5年无息返还，即可三代移民；</p>
        <p>8.允许双重国籍，不要求申请人向原本国籍国家报告；</p>
        <p>9.轻松移民监：第一个五年内住满5天即可。</p>
        <p>10.度假天堂，优美的环境，品质宜居之地。</p>
        <div class="pgc-img"><img loading="lazy" class="size-full wp-image-20711 aligncenter" src="@/assets/Cases/18/4.jpg" width="638" height="370"></div>
        <p><strong>半月湾度假村项目优势：</strong></p>
        <p><strong>☑  世界度假村开发商Replay集团主导开发</strong></p>
        <p><strong>☑  Rosewood奢华酒店品牌入驻管理</strong></p>
        <p><strong>☑  少见的安提瓜半月湾海滩地理资源</strong></p>
        <p><strong>☑  20万美元5年无息返还</strong></p>
        <p><strong>☑  无额外税费</strong></p>
        <p><strong>☑  享受政府申请费减半优惠政策</strong></p>
        <p><strong>☑  安提瓜政府高度支持</strong></p>
        <p><strong>☑  完善的登陆服务，为您排忧解难</strong></p>
        <div class="pgc-img"><img loading="lazy" class="size-full wp-image-20712 aligncenter" src="@/assets/Cases/18/5.jpg" width="640" height="218"></div>
      </div>

      <CasesFooter class="mt-5" :index="info.index"/>
    </div>
  </div>

</template>

<script>
import CasesHeader from "@/components/Cases/CasesHeader";
import CasesFooter from "@/components/Cases/CasesFooter";

export default {
  name: "Cases18",
  components: {CasesFooter, CasesHeader},
  data() {
    return {
      info: this.$route.meta,
    }
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>